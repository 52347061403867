export default class MarginChoice {

    constructor()
    {
        this.marginChoiceComponent = document.querySelectorAll('[data-component-type="margin-choice"]');
    }

    init()
    {
        this.marginChoiceComponent.forEach((e) => {
            let inputs = e.querySelectorAll('input');
            inputs.forEach((i) => {
                i.addEventListener('click', (event) => {
                    inputs.forEach((itwo) => {
                        if (itwo !== event.target) {
                            itwo.checked = false;
                        }
                    });
                });
            });
        });
    }
}