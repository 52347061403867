import $ from 'jquery';
$('[data-toggle="tooltip"]').tooltip();

$(document).on('click', '[data-toggle="dismiss-mobile-tooltip"]', function(e) {
    e.preventDefault();
    $('#mobile-tooltip').animate({
        'bottom': '-50px',
        'opacity': 0
    }, 500, function () {
        $('#mobile-tooltip').remove();
    })
    return false;
});
$(document).on('click', '[data-toggle="mobiletooltip"]', function(e) {
    e.preventDefault();
    if (typeof $(this).attr('title') !== 'undefined' && $(this).attr('title')) {
        $('#mobile-tooltip').remove();
        $('body').append('<div id="mobile-tooltip"><button data-toggle="dismiss-mobile-tooltip"><span class="icon-fermer"></span></button><span>'+$(this).attr('title')+'</span></div>');
    }
    return false;
});

