import $ from 'jquery';


export default class SlickSlider {

    init()
    {
        let slickElements = document.querySelectorAll('[data-slick]:not(.slick-initialized)');
        slickElements.forEach((slide) => {
            if (this.data === true) return;
            this.slick(slide);
        });
    }

    /**
     * @param {HTMLElement} htmlElement
    */
    slick(htmlElement, config = {})
    {
        //Check if is in modal not open
        let modal = htmlElement.closest('.modal');
        if (modal !== null) {
            if (!modal.classList.contains('in')) {
                return;
            }
        }

        var setSameHeight = false;
        var container_btn_slider = $(htmlElement).parent().find('.container-btn-slider');
        var has_container_btn_slider = container_btn_slider.length > 0;
        config = {
            prevArrow:"<button type=\"button\" class=\"btn btn-slider slick-prev slick-arrow\"><i class=\"icon-arrow-left\"></i></button>",
            nextArrow:"<button type=\"button\" class=\"btn btn-slider slick-next slick-arrow\"><i class=\"icon-arrow-right\"></i></button>",
            swipeToSlide: true,
            ...config
        }

        /**
         * @param {Object} custom
        */
        let custom = {};

        let slides_count = htmlElement.getAttribute('data-slick-count');
        slides_count = (slides_count === null ? false : parseInt(slides_count));

        if (htmlElement.getAttribute('data-slick-type') === 'advices') {
            if (has_container_btn_slider) {
                custom = {
                    responsive : [
                        {
                            breakpoint: 768,
                            settings:{
                                appendArrows : container_btn_slider,
                            }
                        }
                    ]
                }
            }

        }

        if (htmlElement.parentNode.id == 'ps_imageslider') {
            custom = {
                swipeToSlide: true,
                arrow: true,
                responsive : [
                    {
                        breakpoint: 1680,
                        settings:{
                            arrows: false,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings:{
                            arrows: false,
                        }
                    }
                ]
            }
        }

        if (htmlElement.getAttribute('data-slick-type') === 'products') {
            setSameHeight = true;
            let settings_992 = (slides_count > 8 ? { slidesToShow: 4, slidesToScroll: 4, rows : 2 } : "unslick");
            let settings_720 = (slides_count > 6 ? { slidesToShow: 3, slidesToScroll: 3, rows : 2} : "unslick");
            let settings_100 = (slides_count > 2 ? { slidesToShow: 2, slidesToScroll: 2, rows : 1} : "unslick");

            custom = {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows:true,
                mobileFirst:true,
                rows : 1,
                lazyLoad: "progressive",
                responsive: [
                    {
                        breakpoint: 992,
                        settings: settings_992
                    },
                    {
                        breakpoint: 720,
                        settings: settings_720
                    },
                    {
                        breakpoint: 100,
                        settings: settings_100
                    }
                ]
            }

            if (has_container_btn_slider) {
                custom.appendArrows = container_btn_slider;
            }
        }

        if (htmlElement.getAttribute('data-slick-type') === 'nav') {
            custom = {
                arrows: false,
                asNavFor: "[data-slick].products-imagescover",
                slidesToShow: 10,
                slidesToScroll: 3,
                focusOnSelect: true,
                centerMode: false,
                rows: 0,
                variableWidth: false,
                vertical: true,
                verticalSwiping: true,
                lazyLoad: "progressive",
                responsive: [
                    {
                        breakpoint: 992,
                        settings:{
                            asNavFor: "[data-slick].products-imagescover",
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            focusOnSelect: true,
                            centerMode: false,
                            rows: 1,
                            variableWidth: true,
                            infinite: true,
                            vertical: false,
                            verticalSwiping: false,
                            // lazyLoad: 'ondemand'
                        }

                    }
                ]
            }
        }

        if (htmlElement.getAttribute('data-slick-type') === 'product-thumbnails') {
            let product_thumb_uniqid = htmlElement.getAttribute('data-slick-uniqid');
            custom = {
                arrows: false,
                slidesToShow: 7,
                slidesToScroll: 1,
                focusOnSelect: true,
                centerMode: false,
                rows: 0,
                variableWidth: false,
                vertical: true,
                verticalSwiping: false,
                lazyLoad: "progressive",
                asNavFor: "#product_cover_"+product_thumb_uniqid,
                responsive: [
                    {
                        breakpoint: 992,
                        settings:{

                            slidesToShow: 7,
                            slidesToScroll: 1,
                            focusOnSelect: true,
                            centerMode: false,
                            // rows: 1,
                            // variableWidth: true,
                            infinite: true,
                            // vertical: false,
                            // verticalSwiping: false,
                            // lazyLoad: 'ondemand'
                        }

                    }
                ]
            }
        }

        if (htmlElement.getAttribute('data-slick-type') === 'product-cover') {
            let product_cover_uniqid = htmlElement.getAttribute('data-slick-uniqid');
            custom = {
                arrows: false,
                dots : true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                lazyLoad: "ondemand",
                mobileFirst:true,
                rows : 1,
                asNavFor: "#product_thumbnails_"+product_cover_uniqid,
                responsive: [
                    {
                        breakpoint: 992,
                        settings:{
                            dots : false
                        }
                    }
                ]
            }
        }


        if (htmlElement.getAttribute('data-slick-type') === 'product-zoom') {
            custom = {
                arrows: true,
                dots : true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                lazyLoad: "progressive",
                mobileFirst:true,
                variableWidth: false,
                fade: true,
                speed: 200,
                cssEase: 'linear',
                rows : 1
            }
        }

        /**
         * Adapté pour le module : pronesis_instagram
         */
        if(htmlElement.getAttribute('data-slick-type') === 'instagram')
        {
            custom = {
                infinite: false,
                speed: 200,
                slidesToShow: 5,
                dots: false,
                slidesToScroll: 1,
                lazyLoad: 'ondemand',
                //appendArrows : $('#pronesis_instagram .btn-slider-container .btn-slider-container-content > div'),
                responsive: [
                    {
                        breakpoint: 1500,
                        settings:{
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }

                    },
                    {
                        breakpoint: 1100,
                        settings:{
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 999,
                        settings:{
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            arrows: false,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings:{
                            arrows: false,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ]
            }
        }

        config = {
            ...config,
            ...custom
        }

        $(htmlElement).slick(config);

        if (setSameHeight) {
            var same_height = 0;
            var outerheight = 0;
            var elements = $(htmlElement).find('.js-product');

            elements.each(function( index ) {
                outerheight = $(this).outerHeight();
                if (same_height < outerheight) {
                    same_height = outerheight;
                }
            });

            elements.height(same_height);
        }
    }

    /**
     * @param {HTMLElement} htmlElement
    */
    unSlick(htmlElement)
    {
        $(htmlElement).slick('unslick');
    }
}

/*
      data-slick='{"asNavFor":"[data-slick].products-imagescover","slidesToShow":
      {if $product.images|count > 2}3{else}2{/if},
      "slidesToScroll": 1,"focusOnSelect": true,"centerMode":false,"rows": 0,"variableWidth": true}'
*/
