import SlickSlider from '../components/carousel/slick';
let page = document.getElementById('cms');
let slick = new SlickSlider;
if(page)
{
    let sliders = document.querySelectorAll('.imgSlider.carousel');
    sliders.forEach((slider)=>{
        // console.log(SlickSlider);
        slick.slick(slider, {
            slidesToShow: slider.getAttribute('data-nb-items')
        });
    });
}